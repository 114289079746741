import React, { useRef, useEffect, useState, useCallback } from 'react';
import './Scrollbar.css';

const Scrollbar = ({ children }) => {
  const scrollRef = useRef(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollTimeoutRef = useRef(null);

  const handleScroll = useCallback(() => {
    if (scrollRef.current) {
      setIsScrolling(true);
      
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }

      scrollTimeoutRef.current = setTimeout(() => {
        setIsScrolling(false);
      }, 1000);

      window.dispatchEvent(new CustomEvent('customScroll', {
        detail: {
          scrollTop: scrollRef.current.scrollTop
        }
      }));
    }
  }, []);

  useEffect(() => {
    const currentRef = scrollRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener('scroll', handleScroll);
      }
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, [handleScroll]);

  return (
    <div 
      ref={scrollRef} 
      className={`custom-scrollbar ${isScrolling ? 'scrolling' : ''}`}
      onMouseEnter={() => setIsScrolling(true)}
      onMouseLeave={() => setIsScrolling(false)}
    >
      {children}
    </div>
  );
};

export default Scrollbar;