import React from 'react';
import './AboutUs.css';
import sampleImage from '../Images/Egypt.jpeg'; 

function AboutUs() {
  return (
    <div className="about-container">
      <div className="about-content">
        <h1 className="large-heading">ABOUT US</h1>
        <div className="about-body">
          <div className="about-image">
            <img src={sampleImage} alt="About Us" />
          </div>
          <div className="about-text-container">
            <div className="about-text">
              <p>
              UGV-DTU, a team of undergraduate students at Delhi Technological
              University is developing and innovating solutions in the field of autonomous ground vehicles. Our team aims to create a "Made in India" solution for complete autonomy.
              </p>
              <h2>Vision</h2>
              <p>
                Inspire research in AI and robotics, making automated systems accessible.
              </p>
              <h2>Mission</h2>
              <p>
                Push the boundaries of AI, fostering innovation and enhancing safety globally.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;