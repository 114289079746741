import React from 'react';
import './Timeline.css';

const Timeline = ({ years, selectedYear, onYearClick, onPrevYear, onNextYear }) => {
  return (
    <div className="timeline-container">
      <div className="timeline-arrow left" onClick={onPrevYear}></div>
      <div className="timeline">
        {years.map((year, index) => (
          <div
            key={index}
            className={`timeline-item ${year === selectedYear ? 'active' : ''}`}
            data-year={year}
            onClick={() => onYearClick(year)}
          ></div>
        ))}
      </div>
      <div className="timeline-arrow right" onClick={onNextYear}></div>
    </div>
  );
};

export default Timeline;