import React from 'react';
import './Achievement.css';
import ugvcImage from '../Images/UGVC 2024.png';
import igvcImage from '../Images/IGVC 2023.jpeg';
import techfest2023Image from '../Images/IIT Bombay Techfest 2023 , 2022.png';
import techfest2022Image from '../Images/IIT Roorkee 2022.jpeg';
import Autodesk from '../Images/Autodesk3.png';
import Matlab from '../Images/Matlab.png'; 
import igvc2019Image from '../Images/IGVC 2023.jpeg'; 

function Achievement() {
  const achievements = [
    {
      title: "UGVC 2024",
      image: ugvcImage,
      description: "Our team secured a Top 5 position in the UGVC category at ICMTC in Cairo, Egypt, and won the Best Presentation Award, along with a 10,000 EGP prize. We were the first team from India to participate in this competition."
    },
    {
      title: "IGVC 2023",
      image: igvcImage,
      description: "Our team secured 3rd place in the Cyber Security Challenge at IGVC 2023, held at Oakland University, USA, and also qualified for the design competition."
    },
    {
      title: "IIT Bombay Techfest 2023",
      image: techfest2023Image,
      description: "Our team secured 2nd place in the MERNifier competition at IIT Bombay's Techfest, one of Asia's largest tech festivals, demonstrating our expertise in MERN stack development and innovation."
    },
    {
      title: "IIT Roorkee Techfest 2022",
      image: techfest2022Image,
      description: " Our team secured 2nd place in the Line Follower Robot (LFR) competition and 3rd place in the Micromouse competition."
    },
    {
      title: "IGVC 2019",
      image: igvc2019Image,
      description: "Our team participated in IGVC 2019 at Oakland University with our vehicle, CENTAUR, securing 5th place in the Cyber Challenge, 4th in the Design Competition (Group C), and 9th overall out of 35+ international participants."
    },
    {
      title: "Autodesk Sustainable Design",
      image: Autodesk,
      description: "Our team earned 1st Runner Up in the Autodesk Sustainable Design Challenge, highlighting our commitment to creating innovative and eco-friendly design solutions."
    },
    {
      title: "MATLAB Mini Drone",
      image: Matlab,
      description: "Our team secured 3rd place in the MATLAB Mini Drone Competition, showcasing our proficiency in drone technology and control systems."
    },
    
  ];

  return (
    <div className="achievement-container">
      <h1 className="achievement-heading">Achievements</h1>
      <hr className="achievement-header-divider" />
      <div className="achievement-content">
        {achievements.map((achievement, index) => (
          <React.Fragment key={index}>
            <div className="achievement-item">
              <div className="achievement-image-container">
                <img src={achievement.image} alt={achievement.title} className="achievement-image" />
              </div>
              <div className="achievement-title-container">
                <h2 className="achievement-title">{achievement.title}</h2>
              </div>
              <div className="achievement-description-container">
                <p className="achievement-description">{achievement.description}</p>
              </div>
            </div>
            {index < achievements.length - 1 && <hr className="achievement-divider" />}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
}

export default Achievement;