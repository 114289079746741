import React from 'react';
import './FrontPage.css';
import VideoSrc from '../Video/Background Video 17.mp4';
import LogoSrc from '../Images/Frontpage Logo 2.png';

function FrontPage() {
  return (
    <div className="front-container">
      <div className="video-background">
        <video autoPlay loop muted playsInline>
          <source src={VideoSrc} type="video/mp4" />
        </video>
      </div>
      <div className="content">
        <div className="logo-container">
          <img src={LogoSrc} alt="Frontpage Logo" className="logo" />
        </div>
        {}
      </div>
    </div>
  );
}

export default FrontPage;