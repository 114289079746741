import React from 'react';
import './Gallery.css';
import Egypt from '../Images/Egypt.jpeg';
import a from '../Images/1 (2).jpg';
import b from '../Images/2 (2).JPG';
import c from '../Images/3.jpg';
import d from '../Images/4.jpg';
import e from '../Images/5.jpeg';
import f from '../Images/6.jpg';
import g from '../Images/7.jpeg';
import h from '../Images/8.jpg';
import i from '../Images/9.jpg';
import j from '../Images/10.jpg';

const Gallery = () => {
  const images = [
    { id: 1, src: Egypt, alt: 'Egypt', width: 'wide' },
    { id: 2, src: a, alt: 'Random Image 2', width: 'narrow' },
    { id: 3, src: b, alt: 'Random Image 3', width: 'wide' },
    { id: 4, src: e, alt: 'Random Image 4', width: 'narrow' },
    { id: 5, src: f, alt: 'Random Image 5', width: 'wide' },
    { id: 6, src: d, alt: 'Random Image 6', width: 'narrow' },
    { id: 7, src: c, alt: 'Random Image 7', width: 'wide' },
    { id: 8, src: g, alt: 'Random Image 8', width: 'narrow' },
    { id: 9, src: h, alt: 'Random Image 9', width: 'wide' },
    { id: 10, src: i, alt: 'Random Image 8', width: 'narrow' },
    { id: 11, src: j, alt: 'Random Image 9', width: 'wide' },
  ];

  return (
    <div className="gallery-container">
      <h1 className="gallery-title">Our Gallery</h1>
      <div className="gallery-masonry">
        {images.map((image) => (
          <div key={image.id} className={`gallery-item ${image.width}`}>
            <img src={image.src} alt={image.alt} loading="lazy" />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Gallery;