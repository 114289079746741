import React, { useState } from 'react';
import './Team.css';
import Timeline from './Timeline';
import DarshanSolanki from '../Images/Darshan Solanki.jpeg';
import LakshyaSinha from '../Images/Lakshya Sinha.jpeg';
import KartikWalia from '../Images/Kartik Walia.jpeg';
import AaryanAgarwal from '../Images/Aaryan Agarwal.jpeg';
import VedantSingh from '../Images/Vedant Singh.jpg';
import MiteshSati from '../Images/Mitesh Sati.jpg';
import YuvrajGupta from '../Images/Yuvraj Gupta.jpeg';
import KrishnaSharma from '../Images/Krishna Sharma.jpeg';
import LakshaySingh from '../Images/Lakshay Singh2.jpeg';
import DakshGupta from '../Images/Daksh Gupta.jpg';
import AbdulBasit from '../Images/Abdul Basit.jpg';
import ProfSIndu from '../Images/Prof. S. Indu2.jpeg';
import ProfNJayanthi from '../Images/Prof N Jayanthi.jpg';
import SwayamAneja from '../Images/Swayam Aneja.jpeg';
import RobinSingh from '../Images/Robin Singh.jpg';
import YatharthAhuja from '../Images/Yatharth Ahuja.JPG';
import ManishBhatia from '../Images/Manish Bhatia.jpeg';
import BhanuGupta from '../Images/Bhanu Gupta.jpg';
import DhruvPal from '../Images/Dhruv Pal.jpg';
import VijayNarayanSingh from '../Images/Vijay Narayan Singh.jpg';
import RohitShukla from '../Images/Rohit Shukla.jpeg';
import VanshJain from '../Images/Vansh Jain.jpg';
import HarshSaini from '../Images/Harsh Saini.jpg';
import AdarshRanjan from '../Images/Adarsh Ranjan.jpg';
import RajatChandra from '../Images/Rajat Chandra.jpg';
import AyushSinha from '../Images/Ayush Sinha.jpg';
import ParthSalunkhe from '../Images/Parth Salunkhe.jpg';
import VaishnaviSaraswat from '../Images/Vaishnavi Saraswat.jpeg';
import Surbhi from '../Images/Surbhi.jpg';
import GatikGupta from '../Images/Gatik_Gupta.jpg';
import GarvitSobti from '../Images/Garvit Sobti.jpeg';
import KaushalKumar from '../Images/Kaushal Kumar.jpeg';
import SpruhaKar from '../Images/Spruha Kar2.png';
import VarnikaVerma from '../Images/Varnika Verma.jpeg';
import AvneetKaur from '../Images/Avneet Kaur.jpeg';
import LakshayShokeen from '../Images/Lakshay Shokeen.jpeg';
import AdityaKumar from '../Images/Aditya Kumar.png';
import HarshitChopra from '../Images/Harshit Chopra.jpeg';
import KavyaAgrawal from '../Images/Kavya Agrawal.jpeg';
import SyedZainAskari from '../Images/Syed Zain Askari.jpg';
import UtkarshDiwakar from '../Images/Utkarsh Diwakar.png';
import Ishan from '../Images/Ishan.png';
import AryanMangal from '../Images/Aryan Mangal.png';
import OmAsati from '../Images/Om Asati.jpg';
import Sahil from '../Images/Sahil.png';
import AdityaJain from '../Images/Aditya Jain.png';
import AayushRaghav from '../Images/Aayush Raghav.jpeg';

const mentors = [
  {
    img: ProfSIndu,
    name: 'Prof. S. Indu',
    role: 'Mentor',
    email: 's.indu@dtu.ac.in',
    linkedin: 'https://www.linkedin.com/in/indu-sreedevi-683840aa/',
  },
  
    {
      img: ProfNJayanthi,
      name: 'Dr. N. Jayanthi',
      role: 'Mentor',
      email: 'njayanthi@dce.ac.in',
    },
];

const council = [
  {
    img: LakshaySingh,
    name: 'Lakshay',
    role: 'Team Captain',
    email: 'business.lakshaysingh@gmail.com',
    linkedin: 'https://www.linkedin.com/in/thelakshaysingh/',
    instagram: 'https://www.instagram.com/ragelakshay?igsh=bzMzaDdpbng0YjEx',
  },

  {
    img: AbdulBasit,
    name: 'Abdul Basit',
    role: 'Vice Captain',
    email: 'abdul7069@gmail.com',
    linkedin: 'https://www.linkedin.com/in/abdul-basit-06a80222b/',
    instagram: 'https://www.instagram.com/abdulbasit5100?igsh=cHl6NHd2dDZ6OWlq',
  },
  {
    img: DakshGupta,
    name: 'Daksh Gupta',
    role: 'Software Head',
    email: '',
    linkedin: 'https://www.linkedin.com/in/daksh-gupta-793b68233/?trk=opento_nprofile_details',
    instagram: 'https://www.instagram.com/daksh_gupta2003?igsh=MW55cXlldGF3eDd4NQ==',
  },
  {
    img: LakshyaSinha,
    name: 'Lakshya Sinha',
    role: 'Mechanical Head',
    email: 'lakshya714xs97@gmail.com',
    linkedin: 'https://www.linkedin.com/in/lakshya-sinha-7b5898229/',
    instagram: 'https://www.instagram.com/_xan_714?igsh=MXBtMXNoZTB2bnI4dw==',
  },
];

const members = [
  {
    img: KartikWalia,
    name: 'Kartik Walia',
    role: 'Software Member',
    email: 'itzkartikeds3@gmail.com',
    linkedin: 'https://www.linkedin.com/in/thekartikwalia/',
    instagram: 'https://www.instagram.com/stoic__kartik?igsh=MTR6MnpqNHh0emMycg==',
  },
  {
    img: AaryanAgarwal,
    name: 'Aaryan Agarwal',
    role: 'Software Member',
    email: 'aaryan.agarwal@gmail.com',
    linkedin: 'https://www.linkedin.com/in/aaryan-agarwal-488582256/',
    instagram: 'https://www.instagram.com/aaryan_agarwal._/',
  },
  {
    img: DarshanSolanki,
    name: 'Darshan Solanki',
    role: 'Software Member',
    email: 'solankidarshan0321@gmail.com',
    linkedin: 'www.linkedin.com/in/darshan-solanki-56139728a',
    instagram: 'https://www.instagram.com/darshann_exe?igsh=MXRjMXpmNXFnaTltMw==',
  },
  {
    img: VedantSingh,
    name: 'Vedant Singh',
    role: 'Software Member',
    email: 'vedantsinggh@gmail.com',
    linkedin: 'https://www.linkedin.com/in/vedantsinggh/',
    instagram: 'https://www.instagram.com/vedantsinggh/',
  },
  {
    img: GatikGupta    ,
    name: 'Gatik Gupta',
    role: 'Software Member',
    email: 'gatikgupta70@gmail.com',
    linkedin: 'https://www.linkedin.com/in/gatik-gupta-51a04b265?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    instagram: 'https://www.instagram.com/gatik.gupta.129?igsh=MXE0bzJzcjl5OHhwMQ==',
  },
  {
    img: VarnikaVerma,
    name: 'Varnika Verma',
    role: 'Software Member',
    email: '',
    linkedin: 'https://www.linkedin.com/in/varnika-verma-230942289/',
    instagram: '',
  },
  {
    img: SyedZainAskari ,
    name: 'Syed Zain Askari',
    role: 'Mechanical Member',
    email: '',
    linkedin: 'https://www.linkedin.com/in/syed-zain-askari-3896b9280/',
    instagram: '',
  },
  {
    img: MiteshSati,
    name: 'Mitesh Sati',
    role: 'Mechanical Member',
    email: 'miteshsati98@gmail.com',
    linkedin: 'https://www.linkedin.com/in/mitesh-sati-ba3271291/',
    instagram: 'https://www.instagram.com/mitesh_sati/',
  },
  {
    img: YuvrajGupta,
    name: 'Yuvraj Gupta',
    role: 'Mechanical Member',
    email: 'yuvrajg151@gmail.com',
    linkedin: 'https://www.linkedin.com/in/yuvraj-g-609a1822a/',
    instagram: 'https://www.instagram.com/_yuvraj808_/',
  },
  {
    img: KrishnaSharma,
    name: 'Krishna Sharma',
    role: 'Mechanical Member',
    email: 'krishnasharma12abh@gmail.com',
    linkedin: 'https://www.linkedin.com/in/krishna-sharma-6b392b288/',
    instagram: 'https://www.instagram.com/kbewillin/',
  },
  {
    img: SwayamAneja,
    name: 'Swayam Aneja',
    role: 'Mechanical Member',
    email: 'swayamaneja679@gmail.com',
    linkedin: 'https://www.linkedin.com/in/swayam-aneja-246b94287?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
    instagram: 'https://www.instagram.com/swayamaneja05?igsh=MTJtaWF4MzU4ODRvNw%3D%3D&utm_source=qr',
  },
  {
    img: RohitShukla    ,
    name: 'Rohit Shukla',
    role: 'Mechanical Member',
    email: 'rohitshukla7045@gmail.com',
    linkedin: 'https://www.linkedin.com/in/rohit-shukla-908445298/',
    instagram: 'https://www.instagram.com/rohitshukla_007/',
  },
  {
    img: AdarshRanjan    ,
    name: 'Adarsh Ranjan',
    role: 'Mechanical Member',
    email: 'adarsh12ranjan@gmail.com',
    linkedin: 'https://www.linkedin.com/in/adarshranjan02077/',
    instagram: 'https://www.instagram.com/adarsh.0207_/',
  },
  {
    img: OmAsati ,
    name: 'Om Asati',
    role: 'Mechanical Member',
    email: '',
    linkedin: 'https://www.linkedin.com/in/om-asati-019a1919a/',
    instagram: 'https://www.instagram.com/0m.asati/',
  },
  {
    img: ParthSalunkhe     ,
    name: 'Parth Salunkhe',
    role: 'Electrical Member',
    email: 'parth.ajit7052@gmail.com',
    linkedin: 'https://www.linkedin.com/in/parth-salunkhe-029a491a4?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    instagram: 'https://www.instagram.com/parth_7052_?igsh=dDhhOXB4amRjbmhs',
  },
  {
    img: Surbhi,
    name: 'Surbhi',
    role: 'Electrical Member',
    email: 'surbhi_ee22b18_42@dtu.ac.in',
    linkedin: 'www.linkedin.com/in/ surbhi-kumari2023',
    instagram: 'https://www.instagram.com/surbhikumari5217?igsh=MXdvNnNtbXU1ZjJjbQ==',
  },
  {
    img:  AvneetKaur,
    name: 'Avneet Kaur',
    role: 'Corporate Member',
    email: '',
    linkedin: 'https://www.linkedin.com/in/avneet-kaur-b9450a291/',
    instagram: '',
  },
  {
    img: VanshJain ,
    name: 'Vansh Jain',
    role: 'Corporate Member',
    email: 'vansh74177@gmail.com',
    linkedin: 'https://www.linkedin.com/in/vansh-jain-416786225?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
    instagram: 'https://www.instagram.com/vanshjain_29?igsh=MXVvNnh0NDBucWYxMQ==',
  },
  {
    img: VijayNarayanSingh ,
    name: 'Vijay Narayan',
    role: 'Corporate Member',
    email: 'narayan41899@gmail.com',
    linkedin: 'https://in.linkedin.com/in/vijay-narayan-singh-358053317',
    instagram: 'https://www.instagram.com/vjsingh899?igsh=MTZ6eTFzbTVvMDZwdQ==',
  },

];

const pastMembersData = {
  '2024': [
    {
      img: RajatChandra      ,
      name: 'Rajat Chandra',
      role: 'Batch of 2025',
      email: 'chandrarajat26@gmail.com',
      linkedin: 'https://www.linkedin.com/in/rajatchandra26/',
      instagram: 'https://www.instagram.com/rajat.0026',
    },
    {
      img: DhruvPal,
      name: 'Dhruv Pal',
      role: 'Batch of 2026',
      email: 'dhruv416pal@gmail.com',
      linkedin: 'https://www.linkedin.com/in/idhruvpal/',
      instagram: 'https://www.instagram.com/idhruvpal',
    },
    {
      img: HarshSaini,
      name: 'Harsh Saini',
      role: 'Batch of 2026',
      email: 'harshsaini_ep22b4_51@dtu.ac.in',
      linkedin: 'https://www.linkedin.com/in/harsh-saini-404875224',
      instagram: 'https://www.instagram.com/whimsical_harsh/',
    },
    {
      img: VaishnaviSaraswat,
      name: 'Vaishnavi Saraswat',
      role: ' Batch of 2026',
      email: 'vaishnavisaraswat_ee22b18_61@dtu.ac.in',
      linkedin: 'https://www.linkedin.com/in/vaishnavi-saraswat-44967424b?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
      instagram: 'https://www.instagram.com/vaishnavii_s04?igsh=MWFtZHJyYW5uZmJqYQ%3D%3D&utm_source=qr',
    },
    {
      img:  SpruhaKar,
      name: 'Spruha Kar',
      role: 'Batch of 2026',
      email: '',
      linkedin: '',
      instagram: '',
    },
    {
      img:  LakshayShokeen,
      name: 'Lakshay Shokeen',
      role: 'Batch of 2027',
      email: '',
      linkedin: '',
      instagram: '',
    },
    {
      img: GarvitSobti ,
      name: 'Garvit Sobti',
      role: 'Batch of 2027',
      email: '',
      linkedin: 'https://www.linkedin.com/in/garvit-sobti-57954022a/',
      instagram: '',
    },
    
  ],
  '2023': [
    {
      img:  Ishan,
      name: 'Aryan Mangal',
      role: 'Batch of 2023',
      email: '',
      linkedin: '',
      instagram: '',
    },
    {
      img: AryanMangal ,
      name: 'Ishan',
      role: 'Batch of 2023',
      email: '',
      linkedin: '',
      instagram: '',
    },
    {
      img: AdityaJain ,
      name: 'Aditya Jain',
      role: 'Batch of 2023',
      email: '',
      linkedin: '',
      instagram: '',
    },
    {
      img: BhanuGupta,
      name: 'Bhanu Gupta',
      role: 'Batch of 2023',
      email: 'guptabhanu59@gmail.com',
      linkedin: 'https://www.linkedin.com/in/bhanu-gupta-200a20142/',
      instagram: 'https://www.instagram.com/bhanu_gupta53?igsh=MTE4enlicmQwZHNhOA==',
    },
    {
      img: AyushSinha,
      name: 'Ayush Sinha',
      role: 'Batch of 2023',
      email: 'ayushsinha.del@gmail.com',
      linkedin: 'https://www.linkedin.com/in/ayushsinha37',
      instagram: 'https://www.instagram.com/ayush.sin_a/',
    },
    {
      img: AayushRaghav,
      name: 'Aayush Raghav',
      role: 'Batch of 2025',
      email: '',
      linkedin: 'https://www.linkedin.com/in/aayush-raghav/',
      instagram: '',
    },
    {
      img:  KaushalKumar,
      name: 'Kaushal Kumar',
      role: 'Batch of 2025',
      email: '',
      linkedin: '',
      instagram: '',
    },
    {
      img: HarshitChopra ,
      name: 'Harshit Chopra',
      role: 'Batch of 2025',
      email: '',
      linkedin: 'https://www.linkedin.com/in/harshit-c-2b1a95120/',
      instagram: '',
    },
    {
      img: AdityaKumar ,
      name: 'Aditya Kumar',
      role: 'Batch of 2026',
      email: '',
      linkedin: 'https://www.linkedin.com/in/aditya-kumar-698a08257/',
      instagram: '',
    },
    {
      img: UtkarshDiwakar ,
      name: 'Utkarsh Diwakar',
      role: 'Batch of 2026',
      email: '',
      linkedin: '',
      instagram: '',
    },
    {
      img:  KavyaAgrawal,
      name: 'Kavya Agarwal',
      role: 'Batch of 2026',
      email: '',
      linkedin: 'https://www.linkedin.com/in/kavya-agrawal-a14179223/',
      instagram: '',
    },
  ],
  '2022': [
    {
      img: YatharthAhuja,
      name: 'Yatharth Ahuja ',
      role: 'Batch of 2022',
      email: 'yahuja@andrew.cmu.edu',
      linkedin: 'https://www.linkedin.com/in/yatharth-ahuja/',
      instagram: 'https://www.instagram.com/yatharth_ahuja/',
    },
  ],
  '2021': [
    {
      img: Sahil ,
      name: 'Sahil',
      role: 'Batch of 2021',
      email: '',
      linkedin: '',
      instagram: '',
    },
    {
      img: ManishBhatia,
      name: 'Manish Bhatia',
      role: 'Batch of 2021',
      email: 'Manish4291@gmail.com',
      linkedin: 'https://www.linkedin.com/in/manish-bhatia-662282152',
      instagram: 'https://www.instagram.com/manishbhatia_?igsh=MTlwb2FkcDN5YThreA%3D%3D&utm_source=qr',
    },

  ],
  '2020': [

    {
      img: RobinSingh,
      name: 'Robin Kr. Singh',
      role: 'Batch of 2020',
      email: 'singhrob@oregonstate.edu',
      linkedin: 'https://www.linkedin.com/in/robinvishen/',
      instagram: 'https://www.instagram.com/robinvishen',
    },

  ],
};

const Team = () => {
  const years = ['2024', '2023', '2022', '2021', '2020']; 
  const [selectedYear, setSelectedYear] = useState(years[0]);

  const handleYearClick = (year) => {
    setSelectedYear(year);
  };

  const handlePrevYear = () => {
    const currentIndex = years.indexOf(selectedYear);
    if (currentIndex > 0) {
      setSelectedYear(years[currentIndex - 1]);
    }
  };

  const handleNextYear = () => {
    const currentIndex = years.indexOf(selectedYear);
    if (currentIndex < years.length - 1) {
      setSelectedYear(years[currentIndex + 1]);
    }
  };

  return (
    <div className="team-page-container">
      <section>
        <h2 className="center-heading">Our Mentors</h2>
        <div className="team-card-container">
          {mentors.map((mentor, index) => (
            <div className="team-card" key={index}>
              <img src={mentor.img} alt={mentor.name} />
              <h3>{mentor.name}</h3>
              <p>{mentor.role}</p>
              <div className="team-icons">
                <a href={`mailto:${mentor.email}`} target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-envelope"></i>
                </a>
                <a href={mentor.linkedin} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-linkedin"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section>
        <h2 className="center-heading">Council</h2>
        <div className="team-card-container">
          {council.map((member, index) => (
            <div className="team-card council-card" key={index}>
              <img src={member.img} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{member.role}</p>
              <div className="team-icons">
                <a href={`mailto:${member.email}`} target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-envelope"></i>
                </a>
                <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href={member.instagram} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section>
        <h2 className="center-heading">Team Members</h2>
        <div className="team-card-container">
          {members.map((member, index) => (
            <div className="team-card" key={index}>
              <img src={member.img} alt={member.name} />
              <h3>{member.name}</h3>
              <p>{member.role}</p>
              <div className="team-icons">
                <a href={`mailto:${member.email}`} target="_blank" rel="noopener noreferrer">
                  <i className="fas fa-envelope"></i>
                </a>
                <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-linkedin"></i>
                </a>
                <a href={member.instagram} target="_blank" rel="noopener noreferrer">
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>
      <section id="past-members-section">
        <h2 className="center-heading">Past Members</h2>
        <Timeline 
          years={years}
          selectedYear={selectedYear}
          onYearClick={handleYearClick} 
          onPrevYear={handlePrevYear}
          onNextYear={handleNextYear}
        />
        <div className="team-card-container">
          {pastMembersData[selectedYear] && pastMembersData[selectedYear].length > 0 ? (
            pastMembersData[selectedYear].map((member, index) => (
              <div className="team-card" key={index}>
                <img src={member.img} alt={member.name} />
                <h3>{member.name}</h3>
                <p>{member.role}</p>
                <div className="team-icons">
                  <a href={`mailto:${member.email}`} target="_blank" rel="noopener noreferrer">
                    <i className="fas fa-envelope"></i>
                  </a>
                  <a href={member.linkedin} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin"></i>
                  </a>
                  <a href={member.instagram} target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            ))
          ) : (
            <p>No past members data available for {selectedYear}</p>
          )}
        </div>
      </section>
    </div>
  );
};

export default Team;