import React, { useState } from 'react';
import './FAQ.css';

const faqData = [
  { question: 'When do the UGV-DTU recruitments commence?', answer: 'Recruitments are held twice a year, in August-September and January-February.' },
  { question: 'Who are eligible for the recruitments?', answer: 'All students in their 1st and 2nd year are eligible for recruitment.' },
  { question: 'Are there any pre-requisites for the interviews?', answer: 'There are no specific prerequisites, but having relevant skills and knowledge in your department related to UGV is beneficial.' },
  { question: 'What is the recruitment process in UGV-DTU?', answer: 'The recruitment process in UGV-DTU involves three stages: an aptitude test, a department-specific test, and a personal interview.' },
  { question: 'How is being in a Tech Team beneficial for academics?', answer: 'Being part of a Tech Team can enhance academics by improving time management skills, which are crucial for effectively balancing both team work and studies.' }
];

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const handleToggle = index => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2>FAQ</h2>
      <div className="faq-container">
        {faqData.map((item, index) => (
          <div key={index} className="faq-item">
            <div
              className={`faq-question ${activeIndex === index ? 'active' : ''}`}
              onClick={() => handleToggle(index)}
            >
              {item.question}
              <span>{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div className={`faq-answer ${activeIndex === index ? 'show' : ''}`}>
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
