import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Navbar from './Components/Navbar';
import FrontPage from './Components/FrontPage';
import AboutUs from './Components/AboutUs';
import Sponser from './Components/Sponser';
import Achievement from './Components/Achievement';
import Contactus from './Components/Contactus';
import Footer from './Components/footer';
import Team from './Components/Team';
import Gallery from './Components/Gallery';
import FAQ from './Components/FAQ'; 
import Scrollbar from './Components/Scrollbar';

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Scrollbar>
          <Routes>
            <Route path="/" element={
              <>
                <FrontPage />
                <div id="about-us"><AboutUs /></div>
                <div id="sponsor"><Sponser /></div>
                <div id="contact-us"><Contactus /></div>
                <Footer />
              </>
            } />
            <Route path="/team" element={<Team />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/faq" element={<FAQ />} />
            <Route path="/achievement" element={<Achievement />} />
          </Routes>
        </Scrollbar>
      </div>
    </Router>
  );
}

export default App;