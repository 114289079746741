import React, { useRef, useState } from 'react';
import emailjs from '@emailjs/browser';
import './Contactus.css';

const ContactUs = () => {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitMessage, setSubmitMessage] = useState('');

  const sendEmail = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    emailjs.sendForm('ugv_dtu', 'template_hbjsqic', form.current, '8yhoTaHlljWHyvWOd')
      .then((result) => {
        console.log(result.text);
        setSubmitMessage('Message sent successfully!');
        form.current.reset();
      }, (error) => {
        console.log(error.text);
        setSubmitMessage('Failed to send message. Please try again.');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <div className="contact-page">
      <div className="background-container">
        <video autoPlay loop muted id="background-video">
          <source src={require('../Video/Contact us.mp4')} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="contact-container">
        <div className="form-container">
          <h2>Contact Us</h2>
          <form ref={form} onSubmit={sendEmail}>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input type="text" id="name" name="from_name" required />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input type="email" id="email" name="from_email" required />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea id="message" name="message" rows="4" required></textarea>
            </div>
            <button type="submit" disabled={isSubmitting}>
              {isSubmitting ? 'Sending...' : 'Submit'}
            </button>
          </form>
          {submitMessage && <p className="submit-message">{submitMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default ContactUs;