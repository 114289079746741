import React from 'react';
import './Sponser.css';
import SolidworksLogo from '../Images/Solidworks.png';
import MathworksLogo from '../Images/Mathworks.png';
import AnsysLogo from '../Images/Ansys.jpg';

function Sponser() {
  return (
    <div className="sponser-container">
      <h1 className="sponser-heading">SPONSORS</h1>
      <div className="sponsor-cards">
        <div className="card">
          <div className="card-front">
            <img src={SolidworksLogo} alt="Solidworks Logo" className="card-image" />
            <div className="card-divider"></div>
            <div className="card-back">
              <p>Solidworks has provided us with invaluable support and tools that have helped us design and build our UGV efficiently.</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-front">
            <img src={MathworksLogo} alt="Mathworks Logo" className="card-image" />
            <div className="card-divider"></div>
            <div className="card-back">
              <p>Mathworks has equipped us with the necessary software and training to enhance our data analysis and simulation capabilities.</p>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-front">
            <img src={AnsysLogo} alt="Ansys Logo" className="card-image" />
            <div className="card-divider"></div>
            <div className="card-back">
              <p>Ansys has enabled us to perform complex simulations, ensuring our UGV meets all safety and performance standards.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sponser;
